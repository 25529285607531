@import "/src/styles/mixin";

.swiper-collection {
  padding: 4px;

  .swiper {
    &-wrapper {
      padding-bottom: 30px;
    }

    &-button {
      @include arrowSwiper;
    }

    &-pagination {
      @include swiperPagination;
    }

    &-slide {
      max-width: 270px;

      &:not(:last-of-type) {
        margin-right: 20px;
      }

      @media screen and (max-width: 1919px) {
        max-width: 230px;

        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }

      @media screen and (max-width: 1439px) {
        max-width: 258px;

        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }

      @media screen and (max-width: 1023px) {
        max-width: 207px;
      }

      @media screen and (min-width: 320px) and (max-width: 559px) {
        max-width: 139px;

        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      padding-bottom: 24px;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 1023px) {
    padding: 0;
  }
}

.swiper-collection-horizontal {
  padding: 6px;

  @media screen and (max-width: 1023px) {
    padding: 0;
  }

  .swiper {
    &-button {
      @include arrowSwiper;

      &-prev,
      &-next {
        //background-color: transparent;
        //box-shadow: none;
        width: 24px;
        height: 24px;
        top: 50%;
        transform: translateY(50%);
        font-size: 9px;
      }

      &-prev {
        left: 1px;
      }

      &-next {
        right: 1px;
      }
    }

    &-pagination {
      @include swiperPagination;
    }

    &-slide {
      width: 100% !important;
      max-width: 345px;
      margin-right: 10px;

      @media screen and (max-width: 1365px) {
        max-width: 315px;
        margin-right: 20px;
      }

      @media screen and (max-width: 1023px) {
        max-width: 288px;
        margin-right: 10px;
      }
    }
  }
}
